import { BaseModel } from 'shared/models/base.model';

export class Location extends BaseModel {
  type: number;
  cityName: string;
  displayName: string;
  stateProvinceCode: string;
  stateProvinceName: string;
  countryCode: string;
  countryName: string;
  coordinate: Coordinate;

  constructor(json?: any) {
    super(json);
  }
}
